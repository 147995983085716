<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12">
            <ValidationProvider name="Org" vid="org_id" rules="required|min_value:1">
              <b-form-group
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.org_id"
                :options="orgList"
                id="org_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12">
            <ValidationProvider name="TrainingType" vid="training_type_id" rules="required|min_value:1">
              <b-form-group
                label-for="training_type_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="form.training_type_id"
                :options="trainingTypeList"
                id="training_type_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
                <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12">
            <ValidationProvider name="Training Title (En)" vid="training_title_en" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="training_title_en">
                <template v-slot:label>
                  {{ $t('elearning_config.training_title') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="training_title_en"
                  v-model="form.training_title_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12">
            <ValidationProvider name="Training Title (Bn)" vid="training_title_bn" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="training_title_bn">
                <template v-slot:label>
                  {{ $t('elearning_config.training_title') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="training_title_bn"
                  v-model="form.training_title_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingTitleStore, trainingTitleUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      trainingTypeList: [],
      trainingCategory: [],
      form: {
        training_title_en: '',
        training_title_bn: '',
        org_id: 0,
        training_type_id: 0
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getMeasurementUnit()
      this.form = tmp
    }
  },
  watch: {
    'form.training_type_id': function (newValue) {
        this.trainingCategory = this.categoryList(newValue)
    },
    'form.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingTypeList = this.getTrainingTypeList(newVal)
      } else {
        this.trainingTypeList = []
      }
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    commodityGroupList () {
      return this.$store.state.BazarMonitoringService.commonObj.commodityGroupList.filter(item => item.status === 1)
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    }
    // trainingTypeList () {
    //   const ttl = this.$store.state.TrainingService.commonObj.trainingTypeList.filter(item => item.status === 1)
    //   return ttl
    // }
  },
  methods: {
    categoryList (typeId) {
       const category = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return category.filter(item => item.training_type_id === parseInt(typeId))
       }
       return category
    },
    getTrainingTypeList (id) {
      const gttl = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === id)
      return gttl
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${trainingTitleUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, trainingTitleStore, this.form)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
         this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getMeasurementUnit () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
